export const GRID_CELL_HEIGHT = 30;
export const GRID_CELL_VMARGIN = 8;
export const GRID_COLUMN_COUNT = 24;
export const REPEAT_DIR_VERTICAL = 'v';
export const REPEAT_DIR_HORIZONTAL = 'h';

export const DEFAULT_PANEL_SPAN = 4;
export const DEFAULT_ROW_HEIGHT = 250;
export const MIN_PANEL_HEIGHT = GRID_CELL_HEIGHT * 3;

export const LS_PANEL_COPY_KEY = 'panel-copy';

export const PANEL_BORDER = 2;

export const EDIT_PANEL_ID = 23763571993;

export const DEFAULT_PER_PAGE_PAGINATION = 40;

export const LS_VISUALIZATION_SELECT_TAB_KEY = 'VisualizationSelectPane.ListMode';

export const LS_WIDGET_SELECT_TAB_KEY = 'WidgetSelectPane.ListMode';

export const customGrafana = {
  bgLight: 'public/img/id-light.svg',
  bgDark: 'public/img/id-dark.svg',
  logoIcon: 'public/img/id-ico.svg',
  login: {
    browserTitle: 'InsightDash - Comm|IT',
    loginTitle: 'By Comm|IT ',
    hideFooter: true,
    fullLogo: 'public/img/id-full-logo.svg',
    style: {
      logoMaxSize: 250,
      boxRadius: '15px',
      mainTitle: {
        size: 18,
      },
    },
  },
};
