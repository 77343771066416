export const palette = {
  white: '#F9F9F9',
  black: '#1E1F23',

  gray25: '#2c3235',
  gray15: '#22252b', //'#202226',
  gray10: '#181b1f', // old '#141619',
  gray05: '#111217', // old '#0b0c0e',

  // new from figma,
  darkLayer0: '#101A33',
  darkLayer1: '#212226',
  darkLayer2: '#292C30', // figma used #34343B but a bit too bright

  darkBorder1: '#34343B',
  darkBorder2: '#64646B',

  // Dashboard bg / layer 0 (light theme)
  gray90: '#F0F1F2',
  // gray90: '#F4F5F5',
  // Card bg / layer 1
  gray100: '#F4F5F5',
  // divider line
  gray80: '#D0D1D3',
  // from figma
  lightBorder1: '#E4E7E7',

  blueDarkMain: '#294999', // '#4165F5',
  blueDarkText: '#3E6EE5', // '#58a6ff', //'#33a2e5', // '#5790FF',
  redDarkMain: '#D10E5C',
  redDarkText: '#FF5286',
  greenDarkMain: '#599973',
  greenDarkText: '#86E5AD',
  orangeDarkMain: '#997C28',
  orangeDarkText: '#E5B93C',

  blueLightMain: '#3E6EE5',
  blueLightText: '#294999',
  redLightMain: '#E0226E',
  redLightText: '#CF0E5B',
  greenLightMain: '#86E5AD',
  greenLightText: '#599973',
  orangeLightMain: '#FFCE43',
  orangeLightText: '#997C28',
};
