import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Last 5 minutes', i18nKey:'last5' },
  { from: 'now-15m', to: 'now', display: 'Last 15 minutes', i18nKey:'last15' },
  { from: 'now-30m', to: 'now', display: 'Last 30 minutes', i18nKey:'last30' },
  { from: 'now-1h', to: 'now', display: 'Last 1 hour', i18nKey:'last1h' },
  { from: 'now-3h', to: 'now', display: 'Last 3 hours', i18nKey:'last3h' },
  { from: 'now-6h', to: 'now', display: 'Last 6 hours', i18nKey:'last6h' },
  { from: 'now-12h', to: 'now', display: 'Last 12 hours', i18nKey:'last12h' },
  { from: 'now-24h', to: 'now', display: 'Last 24 hours', i18nKey:'last24h' },
  { from: 'now-2d', to: 'now', display: 'Last 2 days', i18nKey:'last2d' },
  { from: 'now-7d', to: 'now', display: 'Last 7 days', i18nKey:'last7d' },
  { from: 'now-30d', to: 'now', display: 'Last 30 days', i18nKey:'last30d' },
  { from: 'now-90d', to: 'now', display: 'Last 90 days', i18nKey:'last90d' },
  { from: 'now-6M', to: 'now', display: 'Last 6 months', i18nKey:'last6m' },
  { from: 'now-1y', to: 'now', display: 'Last 1 year', i18nKey:'last1y' },
  { from: 'now-2y', to: 'now', display: 'Last 2 years', i18nKey:'last2y' },
  { from: 'now-5y', to: 'now', display: 'Last 5 years', i18nKey:'last5y' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Yesterday', i18nKey:'yesterday' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Day before yesterday', i18nKey:'beforeYesterday' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'This day last week', i18nKey:'dayLastWeek' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Previous week', i18nKey:'prevWeek' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Previous month', i18nKey:'prevMonth' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Previous fiscal quarter', i18nKey:'prevFiscalQuarter' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Previous year', i18nKey:'prevYear' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Previous fiscal year', i18nKey:'prevFiscalYear' },
  { from: 'now/d', to: 'now/d', display: 'Today', i18nKey:'today' },
  { from: 'now/d', to: 'now', display: 'Today so far', i18nKey:'todayFar' },
  { from: 'now/w', to: 'now/w', display: 'This week', i18nKey:'thisWeek' },
  { from: 'now/w', to: 'now', display: 'This week so far', i18nKey:'weekFar' },
  { from: 'now/M', to: 'now/M', display: 'This month', i18nKey:'thisMonth' },
  { from: 'now/M', to: 'now', display: 'This month so far', i18nKey:'monthFar' },
  { from: 'now/y', to: 'now/y', display: 'This year', i18nKey:'thisYear' },
  { from: 'now/y', to: 'now', display: 'This year so far', i18nKey:'yearFar' },
  { from: 'now/fQ', to: 'now', display: 'This fiscal quarter so far', i18nKey:'fiscalQuarterFar' },
  { from: 'now/fQ', to: 'now/fQ', display: 'This fiscal quarter', i18nKey:'fiscalQuarter' },
  { from: 'now/fy', to: 'now', display: 'This fiscal year so far', i18nKey:'fiscalYearFar' },
  { from: 'now/fy', to: 'now/fy', display: 'This fiscal year', i18nKey:'thisFiscalYear' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];
